import { memo, useEffect } from 'react';
import StatusComponent from './StatusComponent';
import { PAGE_TITLE } from '../../constants/page';

const Pagina403: React.FC = memo(() => {
  useEffect(() => {
    document.title = `${PAGE_TITLE} | 403 - Acceso denegado`;
  }, []);

  return (
    <StatusComponent
      status="403"
      title="Oops 403!"
      subTitle="Parece que no tienes permisos para ver esta página."
    />
  );
});

Pagina403.displayName = 'Pagina403';

export default Pagina403;
