import { memo, useEffect } from 'react';

import { HomePageContainer } from './container';
import Loader from '../../../components/layout/loader';

// Redux
import { eventosActions } from '../../../store/slices/eventos';
import { useAppDispatch, useAppSelector } from '../../../store/store';

// Constants
import { PAGE_TITLE } from '../../../constants/page';

export const HomePage = memo(() => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.eventos.loading);

  useEffect(() => {
    dispatch(eventosActions.fetchEventosFuturos());
    dispatch(eventosActions.fetchEventosPrevios());
    document.title = `${PAGE_TITLE}`;
  }, [dispatch]);

  if (loading) return <Loader />;

  return <HomePageContainer />;
});

HomePage.displayName = 'HomePage';
