import { Swiper, SwiperProps, SwiperRef } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const AUTO_PLAY = {
  delay: 5000,
  disableOnInteraction: true
} as const;

const PAGINATION = {
  clickable: true
} as const;

export const SwiperComponent: React.FunctionComponent<
  React.RefAttributes<SwiperRef> & React.PropsWithChildren<SwiperProps>
> = (props) => (
  <Swiper
    loop={true}
    spaceBetween={10}
    slidesPerView={1}
    autoplay={AUTO_PLAY}
    centerInsufficientSlides={true}
    style={{ marginLeft: 15, marginRight: 15 }}
    pagination={PAGINATION}
    navigation={true}
    {...props}
    breakpoints={{
      320: {
        slidesPerView: 1
      },
      480: {
        slidesPerView: 2
      },
      740: {
        slidesPerView: 3
      },
      1060: {
        slidesPerView: 4
      }
    }}
    modules={[Autoplay, Pagination, Navigation]}
  />
);

SwiperComponent.displayName = 'SwiperComponent';

export default SwiperComponent;
