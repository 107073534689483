import axios from '../config/axios';
import type { TipoEntidad } from '../interfaces/tipoEntidad';

const PATH = `tipos-entidad`;

/**
 * @description Recupera desde la API los tipos de entidades
 */
export const getTiposEntidades = async () => {
  return await axios.get<TipoEntidad[]>(PATH).then((response) => {
    return response.data;
  });
};
