import { createSlice } from '@reduxjs/toolkit';

const initialState: { open: boolean } = { open: false };

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    openNotifications(state) {
      state.open = true;
    },
    closeNotifications(state) {
      state.open = false;
    }
  }
});

export const notificationsActions = notificationsSlice.actions;

export default notificationsSlice.reducer;
