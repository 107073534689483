import { useCallback } from 'react';

import { Avatar, Badge } from 'antd';
import { BellOutlined } from '@ant-design/icons';

// Redux
import { useAppDispatch } from '../../../../store/store';
import { notificationsActions } from '../../../../store/slices/notifications';

// Constants
import { PRIMARY_COLOR } from '../../../../constants/colors';

export const NotificationBell = () => {
  const dispatch = useAppDispatch();

  const onClick = useCallback(() => {
    dispatch(notificationsActions.openNotifications());
  }, [dispatch]);

  return (
    <Badge count={0} offset={[-5, 10]}>
      <span onClick={onClick}>
        <Avatar style={{ backgroundColor: PRIMARY_COLOR }} size={30} icon={<BellOutlined />} />
      </span>
    </Badge>
  );
};
