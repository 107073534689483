import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import EventoService from '../../services/eventos.service';

const initialState: {
  open: boolean;
  errorMessage?: string;
  status: 'loading' | 'success' | 'error';
} = { open: false, status: 'loading' };

export const doAcreditation = createAsyncThunk<void, { cantidad: number; eventoId: number }>(
  'acreditationModal/doAcreditation',
  async ({ cantidad, eventoId }, { rejectWithValue, dispatch }) => {
    dispatch(acreditationModalActions.setAcreditationModal({ open: true }));

    try {
      return await EventoService.selfAcreditarInscripcion(eventoId, cantidad);
    } catch (err: any) {
      let message =
        'Ocurrió un error inesperado al intentar acreditarte en el evento. Por favor intenta nuevamente o acercate al stand.';
      switch (err?.response?.data?.message) {
        case 'Cantidad invalida':
          message =
            'La cantidad de participantes a acreditar es inválida. Si crees que es un error, por favor acercate al stand para que puedan ayudarte.';
          break;
        case 'Inscripcion inexistente':
          message =
            'Parece que no estás inscripto en este evento. Si crees que es un error, por favor acercate al stand para que puedan ayudarte.';
          break;
        case 'Acreditación fuera de termino':
          message =
            'Parece que el período de acreditación no ha empezado. Si crees que es un error, por favor acercate al stand para que puedan ayudarte.';
          break;
        case 'Inscripcion ya acreditada':
          message =
            'Parece que ya estabas acreditado en el evento. Si crees que es un error, por favor acercate al stand para que puedan ayudarte.';
          break;
        default:
          break;
      }
      return rejectWithValue(message);
    }
  }
);

const acreditationModalSlice = createSlice({
  name: 'acreditationModal',
  initialState,
  reducers: {
    setAcreditationModal(state, action: PayloadAction<{ open: boolean }>) {
      state.open = action.payload.open;
      if (action.payload.open) {
        state.status = 'loading';
        state.errorMessage = undefined;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(doAcreditation.fulfilled, (state) => {
      state.status = 'success';
    });
    builder.addCase(doAcreditation.rejected, (state, action) => {
      state.status = 'error';
      state.errorMessage = action.payload as string;
    });
  }
});

export const acreditationModalActions = { doAcreditation, ...acreditationModalSlice.actions };

export default acreditationModalSlice.reducer;
