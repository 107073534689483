/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useCallback, useEffect, useState } from 'react';

import { LeftOutlined } from '@ant-design/icons';
import { Button, Card, Col, Empty, Flex, Row, Tooltip } from 'antd';
import EventCard from '../../../components/event/card';
import WhiteBanner from '../../../components/banner/whiteBanner';

// Utils
import { uniqBy } from 'lodash';
import { useNavigate } from 'react-router-dom';

// Interfaces
import type { Evento } from '../../../interfaces/evento';

// Services
import Loader from '../../../components/layout/loader';
import { getPublicEvents } from '../../../services/eventos.service';

// Constants
import { ROUTES } from '../../../constants/routes-constants';
import { PAGE_TITLE } from '../../../constants/page';

const take = 20 as const;

export const PreviousEventsPage = memo(() => {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const [eventos, setEventos] = useState<Evento[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);

  const getPage = useCallback(() => {
    if (!hasMore) return;
    setLoading(true);
    return getPublicEvents({
      pasados: true,
      take,
      skip: (page - 1) * take,
      orderby: 'fecha',
      orderCriterio: 'DESC'
    })
      .then((response) => {
        const eventos = response[0] as Evento[];
        const total = response[1] as number;
        setPage((p) => p + 1);
        setHasMore(total > page * take);
        setEventos((e) => uniqBy([...e, ...eventos], (e) => e.id));
      })
      .finally(() => {
        setLoading(false);
        setInitialLoad(false);
      })
      .catch(() => {
        setEventos([]);
        setHasMore(false);
      });
  }, [page, hasMore]);

  useEffect(() => {
    getPage();
    document.title = `${PAGE_TITLE} | Eventos realizados`;
  }, []);

  if (initialLoad) return <Loader />;

  return (
    <div style={{ minHeight: 'calc(100vh - 150px)' }}>
      <Row>
        <Col span={24} style={{ background: 'white' }}>
          <Flex justify="space-between" align="center">
            <div style={{ marginLeft: 30 }}>
              <Tooltip title="Volver">
                <Button
                  onClick={() => navigate(ROUTES.HOMEPAGE_ROUTE)}
                  icon={<LeftOutlined />}
                ></Button>
              </Tooltip>
            </div>

            <div style={{ marginLeft: -30 }}>
              <WhiteBanner borderBottom subtitle="Historial de" title="Eventos realizados" />
            </div>

            <div></div>
          </Flex>
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ margin: 20, marginBottom: 30, marginTop: 30 }}>
        {(eventos || [])?.map((event, index) => (
          <Col md={8} xl={6} key={index}>
            <EventCard evento={event} />
          </Col>
        ))}

        {!(eventos || []).length ? (
          <Col span={24}>
            <Card>
              <Flex style={{ minHeight: 'calc(100vh - 400px)' }} justify="center" align="center">
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No se encontraron eventos"
                />
              </Flex>
            </Card>
          </Col>
        ) : null}

        {hasMore ? (
          <Col span={24}>
            <Button
              size="large"
              shape="round"
              type="primary"
              onClick={getPage}
              loading={loading}
              style={{ width: '100%' }}
            >
              Cargar más
            </Button>
          </Col>
        ) : null}
      </Row>
    </div>
  );
});

PreviousEventsPage.displayName = 'PreviousEventsPage';

export default PreviousEventsPage;
