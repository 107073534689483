/* eslint-disable react/no-unescaped-entities */
import { Button, ModalProps } from 'antd';

import { FC, memo, useCallback } from 'react';
import { ModalForm, ProFormDigit } from '@ant-design/pro-form';

// Redux
import { EventoActions } from '../../../store/slices/evento';
import { useAppDispatch, useAppSelector } from '../../../store/store';

// Interfaces
import { Evento, PublicEventRead } from '../../../interfaces/evento';
import { acreditationModalActions } from '../../../store/slices/acreditationModal';

interface ParticipantsConfirmationModalProps {
  evento: Evento | PublicEventRead;
  acreditacionesPendientes: number;
}

const MODAL_PROPS: Omit<ModalProps, 'visible'> = {
  width: 550,
  centered: true,
  closable: false,
  maskClosable: false,
  destroyOnClose: true
};

export const ParticipantsConfirmationModal: FC<ParticipantsConfirmationModalProps> = memo(
  (props) => {
    const { evento, acreditacionesPendientes } = props;

    const dispatch = useAppDispatch();
    const open = useAppSelector((state) => state.evento.participantsModalIsOpen);

    const onClose = useCallback(() => {
      dispatch(EventoActions.setParticipantsModalStatus(false));
    }, [dispatch]);

    return (
      <>
        <ModalForm<{ cantidad: number }>
          open={open}
          size="large"
          title={
            <div style={{ textAlign: 'center', fontSize: 25 }}>
              Bienvenido al evento "{evento?.nombre || ''}"
            </div>
          }
          initialValues={{ cantidad: acreditacionesPendientes }}
          onFinish={async (values) => {
            dispatch(
              acreditationModalActions.doAcreditation({
                eventoId: evento.id,
                cantidad: values.cantidad
              })
            );
            onClose();
            return true;
          }}
          submitter={{
            render(props) {
              return (
                <div style={{ width: '100%', marginTop: 15 }}>
                  <Button
                    type="primary"
                    style={{ width: '100%' }}
                    onClick={props.submit || props.onSubmit}
                  >
                    Confirmar
                  </Button>
                </div>
              );
            }
          }}
          modalProps={{
            ...MODAL_PROPS,
            onClose: onClose,
            onCancel: onClose
          }}
        >
          <p style={{ textAlign: 'center', fontSize: 16, marginTop: 40, marginBottom: 30 }}>
            Para poder confirmar tu participación, necesitamos que nos indiques con cuantas personas
            has venido al evento:
          </p>

          <div>
            <ProFormDigit
              min={1}
              name="cantidad"
              max={acreditacionesPendientes}
              label="Cantidad de participantes"
              placeholder="Ingrese la cantidad de participantes"
              rules={[
                { required: true, message: 'Por favor, ingrese la cantidad de participantes' }
              ]}
            />
          </div>
        </ModalForm>
      </>
    );
  }
);

ParticipantsConfirmationModal.displayName = 'ParticipantsConfirmationModal';

export default ParticipantsConfirmationModal;
