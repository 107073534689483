// import jwtDecode from 'jwt-decode';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: { currentBackground: number } = { currentBackground: 0 };

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setCurrentBackground(state, action: PayloadAction<{ currentBackground: number }>) {
      state.currentBackground = action.payload.currentBackground;
    }
  }
});

export const homeActions = homeSlice.actions;

export default homeSlice.reducer;
