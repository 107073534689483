import { Spin } from 'antd';
import { FC, memo } from 'react';

export const Loader: FC = memo(() => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'calc(100vh - 150px)'
      }}
    >
      <Spin />
    </div>
  );
});

Loader.displayName = 'Loader';

export default Loader;
