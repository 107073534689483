import axios from '../config/axios';

import type { Provincia } from '../interfaces/provincia';

const PATH = `provincias`;

/**
 * @description Recupera desde la API las provincias
 */
export const getProvincias = async () => {
  return await axios.get<Provincia[]>(PATH).then((response) => {
    return response.data;
  });
};

export const ProvinciaService = {
  getProvincias
};
