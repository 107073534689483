import {
  FLUSH,
  PAUSE,
  PURGE,
  PERSIST,
  REGISTER,
  REHYDRATE,
  persistStore,
  persistReducer
} from 'redux-persist';
import expireReducer from 'redux-persist-expire';
import createCompressor from 'redux-persist-transform-compress';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

// Reducers
import authReducer from './slices/auth';
import homeReducer from './slices/home';
import eventoReducer from './slices/evento';
import eventosReducer from './slices/eventos';
import entidadReducer from './slices/entidad';
import productosReducer from './slices/productos';
import provinciasReducer from './slices/provincias';
import notificationsReducer from './slices/notifications';
import tiposEntidadesReducer from './slices/tiposEntidades';
import sectoresActividadReducer from './slices/sectorActividad';
import acreditationModalReducer from './slices/acreditationModal';
import pendingRegistrationModalReducer from './slices/pendingRegistrationModal';

const appReducer = combineReducers({
  auth: authReducer,
  home: homeReducer,
  evento: eventoReducer,
  eventos: eventosReducer,
  entidad: entidadReducer,
  productos: productosReducer,
  provincias: provinciasReducer,
  notifications: notificationsReducer,
  tiposEntidades: tiposEntidadesReducer,
  acreditationModal: acreditationModalReducer,
  sectoresActividad: sectoresActividadReducer,
  pendingRegistrationModal: pendingRegistrationModalReducer
});

// @ts-ignore
const rootReducer = (state, action) => {
  if (action.type === 'auth/removeUser') {
    return appReducer({ eventos: state.eventos }, action);
  }
  return appReducer(state, action);
};

const whitelist = [
  'auth',
  'provincias'
  // 'tiposEntidades', 'sectoresActividad'
] as string[];

const compressor = createCompressor();

const persistedReducer = persistReducer(
  {
    storage,
    whitelist,
    key: 'root',
    transforms: [
      compressor,
      ...whitelist
        .filter((s) => s !== 'auth')
        .map((s) => expireReducer(s, { expireSeconds: 60 * 15 }))
    ]
  },
  rootReducer
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const persistor = persistStore(store);
