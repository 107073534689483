/* eslint-disable react/no-unescaped-entities */
import { FC, memo } from 'react';

// Components
import { Link } from 'react-router-dom';
import { Button, Col, Modal, Result, Row } from 'antd';

// Constants
import { ROUTES } from '../../../../../../constants/routes-constants';

// Interfaces
import type { Evento } from '../../../../../../interfaces/evento';

interface InscriptionConfirmationModalProps {
  open: boolean;
  evento: Evento;
  onClose: () => void;
}

const ButtonStyles = { width: '100%', borderRadius: 15 } as const;

export const PreviouslyRegisteredModal: FC<InscriptionConfirmationModalProps> = memo((props) => {
  const { open, onClose, evento } = props;

  return (
    <Modal closable={false} width={600} open={open} centered footer={false} onClose={onClose}>
      <Row justify="center" align="middle">
        <Col span={24}>
          <Result
            status="warning"
            title="Parece que ya estabas registrado en el evento"
            subTitle={`No pudimos registrar tu inscripción porque parece que ya estabas registrado en el
              evento "${evento.nombre}".`}
          />
        </Col>

        <Col span={24}>
          <Link to={`${ROUTES.PROFILE_ROUTE.replace(':id', evento.id.toString())}?tab=3`}>
            <Button
              shape="round"
              type="primary"
              style={ButtonStyles}
              onClick={() => {
                onClose();
              }}
            >
              Ver mis inscripciones
            </Button>
          </Link>
        </Col>
      </Row>
    </Modal>
  );
});

PreviouslyRegisteredModal.displayName = 'PreviouslyRegisteredModal';

export default PreviouslyRegisteredModal;
