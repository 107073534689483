import { PERMISSIONS_KEYS } from '../constants/permissions';

import type { IUser } from '../interfaces/user';

/** Devuelve un booleano que indica si el usuario tiene permisos para realizar una acción. */
export const isActionableForUser = (user?: IUser, permissions: PERMISSIONS_KEYS[] = []) => {
  if (!user) return false;
  const userPermissions = user?.roles.map((r) => r.permissions?.map((p) => p.descripcion)).flat();

  // Si no tengo el permiso, no se puede realizar la acción
  if (permissions.every((p) => !userPermissions.includes(p))) return false;

  return true;
};
