import React, { CSSProperties, FC, memo } from 'react';
import { PRIMARY_COLOR } from '../../constants/colors';

interface WhiteBannerProps {
  title?: string;
  subtitle?: string;
  action?: React.ReactNode;
  borderTop?: boolean;
  borderBottom?: boolean;
}

const containerStyle = {
  backgroundColor: 'white',
  textAlign: 'center'
} as CSSProperties;

const subtitleStyle = {
  fontSize: 20,
  fontWeight: 300,
  marginBottom: -10
} as CSSProperties;

const actionStyle = {
  margin: 20
} as CSSProperties;

export const WhiteBanner: FC<WhiteBannerProps> = memo((props) => {
  const { title, subtitle, action } = props;

  return (
    <div
      style={{
        // fontFamily: 'DIN',
        ...containerStyle,
        borderTop: props.borderTop ? '1px solid #f0f0f0' : undefined,
        borderBottom: props.borderBottom ? '1px solid #f0f0f0' : undefined
      }}
    >
      {subtitle ? <h3 style={subtitleStyle}>{subtitle}</h3> : null}
      {title ? <h1 style={{ color: PRIMARY_COLOR }}>{title}</h1> : null}
      {action ? <div style={actionStyle}>{action}</div> : null}
    </div>
  );
});

WhiteBanner.displayName = 'WhiteBanner';

export default WhiteBanner;
