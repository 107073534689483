import { Col, Empty, Row } from 'antd';
import { memo } from 'react';

import { SwiperSlide } from 'swiper/react';

import './style.scss';
import EventCard from '../../../../../components/event/card';
import WhiteBanner from '../../../../../components/banner/whiteBanner';

import logo from '../../../../../assets/images/logo_short.png';

// Import Swiper styles
import 'swiper/css';
import SwiperComponent from '../../../../../components/swiper';

// Redux
import { useAppSelector } from '../../../../../store/store';

export const NextEvents = memo(() => {
  const eventos = useAppSelector((state) => state.eventos.futuros || []);

  return (
    <div style={{ marginBottom: 10 }}>
      <Row>
        <Col span={24} style={{ background: 'white' }}>
          <WhiteBanner borderBottom title="Próximos eventos" subtitle="Conocé nuestros" />
        </Col>
      </Row>

      {(eventos || []).length ? (
        <SwiperComponent loop={eventos && eventos.length >= 3}>
          {(eventos || [])?.map((event) => (
            <SwiperSlide key={event.nombre} style={{ paddingTop: 10, paddingBottom: 10 }}>
              <EventCard evento={event} />
            </SwiperSlide>
          ))}
        </SwiperComponent>
      ) : (
        <div style={{ margin: 40 }}>
          <Empty
            image={logo}
            description={
              <span style={{ fontSize: 16, color: '#015f80', fontWeight: 500, marginTop: 20 }}>
                No se encontraron eventos disponibles
              </span>
            }
          />
        </div>
      )}
    </div>
  );
});

NextEvents.displayName = 'NextEvents';

export default NextEvents;
