/**
 * @description Capitalize the first letter of each word in a string
 * @param string
 * @returns string
 */
export const capitalizeFirstLetter = (string: string) => {
  return string
    .split(' ')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(' ');
};
