/* eslint-disable react-hooks/exhaustive-deps */
import {
  EyeOutlined,
  EditOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  EnvironmentOutlined,
  EyeInvisibleOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Button, Card, Col, message, Row, Space, Tooltip } from 'antd';

// Utils
import dayjs from 'dayjs';
import ReactHtmlParser from 'react-html-parser';
import { isActionableForUser } from '../../../../../utils/validateRole';

// Redux
import { EventoActions } from '../../../../../store/slices/evento';
import { useAppDispatch, useAppSelector } from '../../../../../store/store';

// Services
import EventoService from '../../../../../services/eventos.service';

// Constants
import { PRIMARY_COLOR } from '../../../../../constants/colors';
import { ROUTES } from '../../../../../constants/routes-constants';
import { PERMISSIONS } from '../../../../../constants/permissions';

// Utils
import { isEventoVisible } from '../../../../../utils/isEventoVisible';

// Interfaces
import { memo, useMemo, type FC } from 'react';
import type { PublicEventRead } from '../../../../../interfaces/evento';

interface EventContentProps {
  evento: PublicEventRead;
  setEvento: (evento: PublicEventRead) => void;
}

export const EventContent: FC<EventContentProps> = memo((props) => {
  const { evento, setEvento } = props;

  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);

  const visible = useMemo(() => {
    return isEventoVisible(evento);
  }, [evento.visible_desde]);

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card
          title={
            <h1 style={{ overflowWrap: 'break-word', whiteSpace: 'normal' }}>{evento.nombre}</h1>
          }
          styles={{ header: { border: 0 }, body: { margin: 0, padding: 0 } }}
          extra={
            <Space wrap>
              {isActionableForUser(user!, [PERMISSIONS.EVENTOS_UPDATE]) ? (
                <Tooltip title={visible ? 'Ocultar evento' : 'Publicar evento'}>
                  <Button
                    shape="round"
                    icon={visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                    onClick={() =>
                      EventoService.updateEvento(evento.id, {
                        visible_desde: visible ? null : dayjs().toISOString()
                      }).then((e) => {
                        message.success(
                          `El evento "${e.nombre}" ${
                            isEventoVisible(e) ? 'se ha publicado' : 'se ha ocultado'
                          } correctamente.`
                        );
                        setEvento(e);
                        dispatch(EventoActions.setEvento(e));
                      })
                    }
                  />
                </Tooltip>
              ) : null}
              {isActionableForUser(user!, [PERMISSIONS.EVENTOS_UPDATE]) ? (
                <Tooltip title="Editar evento">
                  <Link to={ROUTES.EDIT_EVENT_ROUTE.replace(':id', evento.id.toString())}>
                    <Button shape="round" type="primary" icon={<EditOutlined />}></Button>
                  </Link>
                </Tooltip>
              ) : null}
            </Space>
          }
        ></Card>
      </Col>
      <Col span={24}>
        <Card
          styles={{ header: { border: 0 } }}
          title={<h3 style={{ marginBottom: 0 }}>Información</h3>}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Space>
                <CalendarOutlined />
                <strong style={{ width: 50 }}>Fecha:</strong>{' '}
                <span>{dayjs(evento.fecha).format('DD/MM/YYYY')}</span>
              </Space>
            </Col>
            <Col span={24}>
              <Space>
                <ClockCircleOutlined />
                <strong>Hora:</strong>{' '}
                <span style={{ marginLeft: 7 }}>{dayjs(evento.fecha).format('HH:mm')} hs</span>
              </Space>
            </Col>
            <Col span={24}>
              <Space>
                <EnvironmentOutlined />
                <strong>Lugar:</strong>{' '}
                {evento.maps ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={evento.maps}
                    style={{ color: PRIMARY_COLOR }}
                  >
                    {evento.lugar}
                  </a>
                ) : (
                  <span>{evento.lugar || 'Sin especificar'}</span>
                )}
              </Space>
            </Col>
          </Row>
        </Card>
      </Col>

      {evento.descripcion ? (
        <Col span={24}>
          <Card>
            <div>
              <div className="quill-text">{ReactHtmlParser(evento.descripcion)}</div>
            </div>
          </Card>
        </Col>
      ) : null}
    </Row>
  );
});

EventContent.displayName = 'EventContent';

export default EventContent;
