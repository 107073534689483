import { FC, memo } from 'react';

// Components
import { InscriptionForm } from './form';
import { Affix, Button, Card } from 'antd';

// Utils
import { getEventoImagePath } from '../../../../../utils/getImagePath';

// Intefaces
import type { Evento } from '../../../../../interfaces/evento';

interface SubscribeCardProps {
  evento: Evento;
}

const RegisterButton: FC<SubscribeCardProps> = memo(() => {
  return (
    <Button ghost type="primary" shape="round" style={{ width: '100%', height: 40 }}>
      Registrarse en el evento
    </Button>
  );
});

RegisterButton.displayName = 'RegisterButton';

export const SubscribeCard: FC<SubscribeCardProps> = memo((props) => {
  const { evento } = props;

  return (
    <Affix offsetTop={70}>
      <Card
        cover={
          <div className="event-card-image">
            <img
              alt="imagen-del-evento"
              src={getEventoImagePath(evento, true)}
              style={{ height: 240, width: '100%', objectFit: 'cover' }}
            />
          </div>
        }
      >
        <div>
          <InscriptionForm evento={evento} />
        </div>
      </Card>
    </Affix>
  );
});

SubscribeCard.displayName = 'SubscribeCard';

export default SubscribeCard;
