import { memo } from 'react';

import { BackgroundBanner } from '../../../../../components/banner/backgroundBanner';

export const HomeBanner2 = memo(() => {
  return (
    <BackgroundBanner
      fontSize={17}
      title="¿Cómo funcionan?"
      description={[
        'Su objetivo es posibilitar el intercambio entre las empresas participantes, iniciar contactos comerciales y/o institucionales e incentivar la creación de un escenario propicio para la concreción de negocios, representaciones y todo aquello que impulse la competitividad.',
        'En las rondas confluyen tanto la industria como el comercio y los proveedores de servicios. Pueden ser de carácter sectorial o multisectorial, y a su vez, de acuerdo a la amplitud geográfica de su convocatoria, pueden ser locales o regionales.'
      ]}
    />
  );
});

HomeBanner2.displayName = 'HomeBanner2';
