import { FC, memo } from 'react';
import { Affix, Col, Row } from 'antd';

// Components
import EventContent from './components/content';
import SubscribeCard from './components/subscribeCard';
import AddCompanyModal from '../../../../components/modals/addCompanyModal';

// Interfaces
import type { PublicEventRead } from '../../../../interfaces/evento';

interface EventDetailsContainerProps {
  isAcredited: boolean;
  isInscripted: boolean;
  evento: PublicEventRead;
  setEvento: (evento: PublicEventRead) => void;
}

export const EventDetailsContainer: FC<EventDetailsContainerProps> = memo((props) => {
  const { evento, isInscripted, isAcredited, setEvento } = props;
  return (
    <>
      <AddCompanyModal />
      <div style={{ margin: 20, minHeight: 'calc(100vh - 190px)' }}>
        <Row gutter={[16, 16]}>
          <Col span={24} md={13} lg={16}>
            <EventContent setEvento={setEvento} evento={evento!} />
          </Col>
          <Col span={24} md={11} lg={8}>
            <Affix offsetTop={70}>
              <SubscribeCard
                evento={evento!}
                isAcredited={isAcredited}
                isInscripted={isInscripted}
              />
            </Affix>
          </Col>
        </Row>
      </div>
    </>
  );
});

EventDetailsContainer.displayName = 'EventDetailsContainer';

export default EventDetailsContainer;
