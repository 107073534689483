import axios from '../config/axios';

import type {
  Evento,
  PublicEventRead,
  PublicEventList,
  EventInscription,
  EventoQueryParams,
  NewEventFormValues,
  EditEventFormValues,
  PublicEventsQueryParams,
  EventInscriptionsQueryParams
} from '../interfaces/evento';
import type { PaginatedResponse } from '../interfaces/baseApiEntity';
import { Reunion } from '../interfaces/reunion';

const PATH = `eventos`;

/**
 * @description Recupera desde la API los sectores de actividad
 */
export const getEventos = async (params: EventoQueryParams) => {
  return await axios.get<PaginatedResponse<Evento>>(PATH, { params }).then((response) => {
    return response.data;
  });
};

/**
 * @description Recupera desde la API un evento por su id
 */
export const getEventoById = async (id: number) => {
  return await axios.get<Evento>(`${PATH}/${id}`).then((response) => {
    return response.data;
  });
};

/**
 * @description Crea un nuevo evento en la API
 */
export const createEvento = async (data: NewEventFormValues) => {
  return await axios.post<Evento>(PATH, data).then((response) => {
    return response.data;
  });
};

/**
 * @description Actualiza un evento en la API
 */
export const updateEvento = async (id: number, data: EditEventFormValues) => {
  return await axios.patch<Evento>(`${PATH}/${id}`, data).then((response) => {
    return response.data;
  });
};

/**
 * @description Sube una imagen para asociar a un evento
 */
export const uploadEventoImage = async (id: number, image: File) => {
  const formData = new FormData();
  formData.append('logo', image);

  return await axios.patch(`${PATH}/${id}/setLogo`, formData).then((response) => {
    return response.data;
  });
};

/**
 * @description Elimina un evento en la API
 * @param id
 */
export const deleteEvento = async (id: number) => {
  return await axios.delete(`${PATH}/${id}`).then((response) => {
    return response.data;
  });
};

/**
 * @description Obtiene los eventos públicos de la API
 */
export const getPublicEvents = async (params: PublicEventsQueryParams) => {
  return await axios
    .get<[PublicEventList[], number]>(`/public/eventos`, { params })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Obtiene un evento público por su id
 * @param id
 */
export const getPublicEventById = async (id: number) => {
  return await axios.get<PublicEventRead>(`/public/eventos/${id}`).then((response) => {
    return response.data;
  });
};

/**
 * @description Obtiene las inscripciones a un evento
 */
export const getEventInscriptions = async (
  id: number,
  params: EventInscriptionsQueryParams = {}
) => {
  return await axios
    .get<PaginatedResponse<EventInscription>>(`/eventos/${id}/inscripciones`, { params })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Acredita una inscripción
 * @requires Permiso INSCRIPCIONES_ACREDITAR
 */
export const acreditarInscripcion = async (
  evento_id: number,
  inscripcion_id: number,
  cantidad = 1
) => {
  return await axios
    .post(`${PATH}/${evento_id}/acreditar`, { inscripcion_id, cantidad })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Desacredita una inscripción
 * @requires Permiso INSCRIPCIONES_DESACREDITAR
 */
export const desacreditarInscripcion = async (evento_id: number, inscripcion_id: number) => {
  return await axios
    .post(`${PATH}/${evento_id}/desacreditar`, { inscripcion_id })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Auto-acredita una inscripción
 * @requires Permiso INSCRIPCIONES_AUTO_ACREDITAR
 */
export const selfAcreditarInscripcion = async (evento_id: number, cantidad: number) => {
  return await axios.post(`${PATH}/${evento_id}/selfAcreditar`, { cantidad }).then((response) => {
    return response.data;
  });
};

/**
 * @description Cierra la acreditación de un evento
 * @requires Permiso CERRAR_ACREDITACION
 */
export const cerrarAcreditacion = async (
  evento_id: number,
  data: {
    reuniones_fin: string;
    reuniones_inicio: string;
  }
) => {
  return await axios
    .post<Evento>(`${PATH}/${evento_id}/cerrarAcreditacion`, data)
    .then((response) => {
      return response.data;
    });
};

/**
 *
/**
 * @description Obtiene los turnos de un evento por su id
 */
export const getEventTurnos = async (id: number) => {
  return await axios.get<string[]>(`${PATH}/${id}/turnos`).then((response) => {
    return response.data;
  });
};

/**
 * @description Obtiene las reuniones de un evento por su id
 */
export const getEventReuniones = async (id: number) => {
  return await axios.get<Reunion[]>(`${PATH}/${id}/reuniones`).then((response) => {
    return response.data;
  });
};

/**
 * @description Obtiene las reuniones de un evento por su id
 */
export const getSelfEventReuniones = async (id: number) => {
  return await axios.get<Reunion[]>(`${PATH}/${id}/reuniones/self`).then((response) => {
    return response.data;
  });
};

export const EventoService = {
  getEventos,
  getEventoById,
  createEvento,
  updateEvento,
  deleteEvento,
  getEventTurnos,
  getPublicEvents,
  getEventReuniones,
  uploadEventoImage,
  getPublicEventById,
  cerrarAcreditacion,
  getEventInscriptions,
  acreditarInscripcion,
  getSelfEventReuniones,
  desacreditarInscripcion,
  selfAcreditarInscripcion
};

export default EventoService;
