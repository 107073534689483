/* eslint-disable react/react-in-jsx-scope */

import { FC, memo, useEffect, useState } from 'react';

// Components
import { Link } from 'react-router-dom';
import { Button, Col, message, Result, Row } from 'antd';

// Constants
import { ROUTES } from '../../../constants/routes-constants';

// Services
import AuthService from '../../../services/auth.service';

const ResendButton: FC<{ email: string }> = (props) => {
  const [counter, setCounter] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    // @ts-ignore
    return () => clearInterval(timer);
  }, [counter]);

  const handleResend = () => {
    AuthService.resendEmailVerification(props.email)
      .then(() => {
        setCounter(60);
        message.success('Email reenviado correctamente.');
      })
      .catch(() => {
        message.error(
          'Ocurrió un error inesperado al reenviar el email. Por favor intenta nuevamente.'
        );
      })
      .finally(() => setLoading(false));
  };

  return (
    <Button
      size="large"
      key="resend"
      shape="round"
      type="primary"
      loading={loading}
      disabled={counter > 0}
      onClick={handleResend}
      style={{ width: '70%' }}
    >
      {counter > 0 ? `Reenviar email en ${counter} s` : 'Reenviar email'}
    </Button>
  );
};

export const RegisterSuccessComponent: FC<{ email: string }> = memo((props) => {
  return (
    <>
      <Result
        status="success"
        title="Registro exitoso"
        style={{ marginBottom: 70 }}
        extra={
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <ResendButton email={props.email} />
            </Col>
            <Col span={24}>
              <Link to={ROUTES.LOGIN_ROUTE}>
                <Button size="large" key="go-back" shape="round" style={{ width: '70%' }}>
                  Volver
                </Button>
              </Link>
            </Col>
          </Row>
        }
        subTitle={
          <div style={{ marginTop: 30, fontSize: 16 }}>
            Se le ha enviado un email con los pasos a seguir. Es necesario que verifique su email
            para poder iniciar sesión en el sistema.
            <div style={{ marginTop: 20, marginBottom: 70 }}>
              En caso de no haber recibido el email, haga click en el botón para reenviarlo.
            </div>
          </div>
        }
      />
    </>
  );
});

RegisterSuccessComponent.displayName = 'RegisterPage';

export default RegisterSuccessComponent;
