import { FC, memo, useCallback } from 'react';

// Components
import { Button, Col, Modal, Row } from 'antd';

// Images
import registrationIcon from '../../assets/images/registration.png';

// Utils
import { useAppDispatch, useAppSelector } from '../../store/store';

// Constants
import { ROUTES } from '../../constants/routes-constants';

// Redux
import { pendingRegistrationModalActions } from '../../store/slices/pendingRegistrationModal';

interface AddCompanyModalProps {
  title?: string;
  subtitle?: string;
}

const ButtonStyles = { width: '100%', borderRadius: 15 } as const;

export const AddCompanyModal: FC<AddCompanyModalProps> = memo((props) => {
  const dispatch = useAppDispatch();

  const open = useAppSelector((state) => state.pendingRegistrationModal.open);

  const onClose = useCallback(() => {
    dispatch(pendingRegistrationModalActions.setPendingRegistrationModal(false));
  }, [dispatch]);

  return (
    <Modal
      centered
      width={700}
      open={open}
      footer={false}
      closable={false}
      onClose={onClose}
      className="welcome-modal"
    >
      <Row justify="center" align="middle">
        <Col span={24}>
          <div style={{ textAlign: 'center', marginTop: 20 }}>
            <img style={{ height: 100 }} src={registrationIcon} />
          </div>
        </Col>

        <Col span={24}>
          <div style={{ textAlign: 'center', marginBottom: 25 }}>
            <h1>{props.title || 'Registro de Información necesaria'}</h1>

            <h3>
              {props.subtitle ||
                'Antes de continuar con tu inscripción es necesario que registres en el sistema la información de tu empresa.'}
            </h3>
          </div>
        </Col>

        <Col span={24}>
          <Button
            shape="round"
            style={ButtonStyles}
            type="primary"
            onClick={() => {
              window.open(ROUTES.REGISTER_ENTITY_ROUTE, '_blank');
              onClose();
            }}
          >
            Ir a completar información de mi empresa
          </Button>

          <Button shape="round" onClick={onClose} style={{ ...ButtonStyles, marginTop: 10 }}>
            Quiero hacerlo luego
          </Button>
        </Col>
      </Row>
    </Modal>
  );
});

AddCompanyModal.displayName = 'AddCompanyModal';

export default AddCompanyModal;
