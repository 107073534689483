// import jwtDecode from 'jwt-decode';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { IUser } from '../../interfaces/user';

const me = localStorage.getItem('me');

let user = null;

if (me) {
  try {
    user = JSON.parse(me);
  } catch (err) {
    console.log(err);
  }
}

const initialState: { user: IUser | null } = { user };

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<{ user: IUser }>) {
      const me = action.payload.user;
      localStorage.setItem('me', JSON.stringify(me));
      state.user = me;
    },
    removeUser(state) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      state.user = null;
    }
  }
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
