import { Button, Card } from 'antd';
import { FC, memo, useCallback } from 'react';
import Meta from 'antd/es/card/Meta';

import './style.scss';

// Utils
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

// Interface
import type { Evento } from '../../../interfaces/evento';

// Constants
import { ROUTES } from '../../../constants/routes-constants';
import { getEventoImagePath } from '../../../utils/getImagePath';

interface EventCardProps {
  evento: Evento;
}

export const EventCard: FC<EventCardProps> = memo((props) => {
  const { evento } = props;

  const navigate = useNavigate();

  const onClick = useCallback(() => {
    navigate(ROUTES.EVENT_DETAILS_ROUTE.replace(':id', evento.id.toString()));
  }, [evento.id, navigate]);

  return (
    <Card
      hoverable
      className="event-card"
      cover={
        <div className="event-card-image">
          <img alt="imagen-del-evento" src={getEventoImagePath(evento, true)} />
        </div>
      }
    >
      <Meta
        title={evento.nombre}
        description={
          <div>
            <div>
              <b>Lugar:</b> {evento.lugar}
            </div>
            <div>
              <b>Fecha:</b> {dayjs(evento.fecha).format('DD/MM/YYYY HH:mm')} hs
            </div>
          </div>
        }
      />

      <Button
        shape="round"
        type="primary"
        onClick={onClick}
        style={{ width: '100%', marginBottom: -10, marginTop: 30 }}
      >
        Ver más
      </Button>
    </Card>
  );
});

EventCard.displayName = 'EventCard';

export default EventCard;
