/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import * as EventoService from '../../services/eventos.service';
import type { Evento } from '../../interfaces/evento';

type Entity = Evento;

export const fetchEventosPrevios = createAsyncThunk<Entity[]>('eventos/fetchPasado', (_params) => {
  return EventoService.getPublicEvents({ pasados: true, take: 10, orderCriterio: 'DESC' }).then(
    (response) => {
      return response[0] as any as Entity[];
    }
  );
});

export const fetchEventosFuturos = createAsyncThunk<Entity[]>('eventos/fetchFuturo', (_params) => {
  return EventoService.getPublicEvents({ futuros: true, take: 10 }).then((response) => {
    return response[0] as any as Entity[];
  });
});

interface TiposCooperativasState {
  loading: boolean;
  futuros: Entity[];
  pasados: Entity[];
}

const initialState: TiposCooperativasState = { loading: true, futuros: [], pasados: [] };

const eventosSlice = createSlice({
  name: 'eventos',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEventosPrevios.fulfilled, (state, action) => {
      state.pasados = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchEventosFuturos.fulfilled, (state, action) => {
      state.futuros = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchEventosFuturos.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchEventosFuturos.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const eventosActions = {
  ...eventosSlice.actions,
  fetchEventosPrevios,
  fetchEventosFuturos
};

export default eventosSlice.reducer;
