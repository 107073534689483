import Axios from 'axios';
import { API_URL } from '../config';

import type { IToken, UserCreateFormValues } from '../interfaces/user';

import MeService from './me.service';

const axios = Axios.create({
  baseURL: API_URL + '/auth/'
});

/** Este endpoint permite realizar la autenticación de los usuarios */
const login = async (email: string, password: string) => {
  const response = await axios.post<IToken>(`login`, {
    email,
    password
  });
  localStorage.setItem('accessToken', response.data.token);
  localStorage.setItem('refreshToken', response.data.refresh_token);
  return await MeService.getCurrentUser();
};

const logout = async () => {
  localStorage.removeItem('user');
  return await axios.post<void>(`logout`).then((response) => {
    return response.data;
  });
};

const register = async (values: UserCreateFormValues) => {
  return await axios.post<void>(`register`, values).then((response) => {
    return response.data;
  });
};

/** Este endpoint genera un código de recuperación de contraseña que se envia al email del usuario. */
const forgotPassword = async (email: string) => {
  return await axios.post<void>(`initiatePasswordReset`, { email }).then((response) => {
    return response.data;
  });
};

/** Este endpoint se utiliza para setear una nueva contraseña al usuario utilizando el código enviado por email. */
const resetPassword = async (token: string, password: string) => {
  return await axios.post<any>(`resetPassword`, { token, password }).then((response) => {
    return response.data;
  });
};

/** Este endpoint se utiliza para validar el email del usuario. */
const validateEmail = async (token: string) => {
  return await axios.post<any>(`validateEmail`, { token }).then((response) => {
    return response.data;
  });
};

const resendEmailVerification = async (email: string) => {
  return await axios.post<any>(`resendEmailVerification`, { email }).then((response) => {
    return response.data;
  });
};

const AuthService = {
  login,
  logout,
  register,
  validateEmail,
  resetPassword,
  forgotPassword,
  resendEmailVerification
};

export default AuthService;
