/* eslint-disable no-useless-escape */
export const PASSWORD_MIN_LENGTH = 8; // Minimum length of password
export const PASSWORD_MAX_LENGTH = 10640; // Maximum length of password

const SPECIAL_CHARACTERS = `!@#$%^&*()_+-={};\':"\\\|,.<>/?`; // Allowed special characters

const REGEX_STRING = [
  '^', // Start of string
  '(?=.*[a-z])', // At least one lowercase letter
  '(?=.*[A-Z])', // At least one uppercase letter
  '(?=.*\\d)', // At least one digit
  `(?=.*[${SPECIAL_CHARACTERS}])`, // At least one special character
  `[A-Za-z\\d${SPECIAL_CHARACTERS}]`, // All allowed characters
  `{${PASSWORD_MIN_LENGTH},${PASSWORD_MAX_LENGTH}}`, // Length of string
  '$' // End of string
].join('');

export const PASSWORD_REGEX = new RegExp(REGEX_STRING);
