import { LockOutlined } from '@ant-design/icons';
import { AuthLayout } from '../components/layout';
import { Button, message, notification } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { memo, useCallback, useEffect, useRef } from 'react';
import ProForm, { ProFormInstance, ProFormText } from '@ant-design/pro-form';

// Services
import AuthService from '../../../services/auth.service';

// Interfaces
import type { FC } from 'react';
import type { ResetPasswordParams } from './interfaces';

// Constants
import { PAGE_TITLE } from '../../../constants/page';
import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH, PASSWORD_REGEX } from '../../../utils/password';

import './styles.scss';

export const ForgetPassword: FC = memo(() => {
  const ref = useRef<ProFormInstance<ResetPasswordParams>>();
  const navigate = useNavigate();

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        ref.current?.submit();
      }
    };
    document.title = `${PAGE_TITLE} | Restablecer contraseña`;
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get('token');
    if (!token) navigate('/');
  }, [navigate]);

  const onSubmit = useCallback(
    async (data: ResetPasswordParams) => {
      const code = new URLSearchParams(window.location.search).get('token') || '';
      return await AuthService.resetPassword(code, data.password)
        .then(() => {
          message.success('Contraseña actualizada correctamente.');
          navigate('/');
          return true;
        })
        .catch((e: any) => {
          if (e && e.response && e.response.data && e.response.data.message) {
            notification.error({
              closeIcon: false,
              placement: 'bottomLeft',
              description: e.response.data.message,
              message: 'Error'
            });
            return false;
          }

          notification.error({
            closeIcon: false,
            placement: 'bottomLeft',
            description:
              'Ocurrió un error inesperado al actualizar la contraseña. Si el problema persiste, ponganse en contacto con el area de soporte.',
            message: 'Error'
          });

          return false;
        });
    },
    [navigate]
  );

  return (
    <AuthLayout>
      <ProForm<ResetPasswordParams>
        formRef={ref}
        autoComplete="off"
        onFinish={onSubmit}
        submitter={{
          render(props) {
            return (
              <div className="login-page-footer">
                <div style={{ marginTop: 15 }}>
                  <Button
                    {...props.submitButtonProps}
                    size="large"
                    style={{ width: '100%' }}
                    shape="round"
                    type="primary"
                    onClick={props.submit}
                  >
                    Actualizar contraseña
                  </Button>

                  <Link to="/ingresar">
                    <Button
                      {...props.submitButtonProps}
                      size="large"
                      shape="round"
                      type="primary"
                      style={{ marginTop: 15, width: '100%' }}
                      loading={false}
                    >
                      Volver a inicio
                    </Button>
                  </Link>
                </div>

                <img
                  className="login-page-footer-logo"
                  src={`${process.env.PUBLIC_URL}/logo_gba.png`}
                />
              </div>
            );
          }
        }}
      >
        <ProFormText.Password
          name="password"
          label="Nueva contraseña"
          fieldProps={{
            size: 'large',
            autoComplete: 'off',
            style: { borderRadius: 20 },
            prefix: <LockOutlined className={'prefixIcon'} />
          }}
          placeholder="Ingrese la nueva contraseña"
          tooltip="La contraseña debe tener al menos una letra mayúscula, una letra minúscula, un número y un caracter especial."
          rules={[
            { min: PASSWORD_MIN_LENGTH, message: 'Debe tener al menos 8 caracteres' },
            { max: PASSWORD_MAX_LENGTH, message: 'La contraseña es demasiado larga' },
            { required: true, message: ' ' },
            {
              pattern: PASSWORD_REGEX,
              message:
                'La contraseña debe tener al menos una letra mayúscula, una letra minúscula, un número y un caracter especial.'
            }
          ]}
        />

        <ProFormText.Password
          name="new_password_two"
          label="Reingrese la nueva contraseña"
          placeholder="Reingrese su contraseña actual"
          fieldProps={{
            size: 'large',
            autoComplete: 'off',
            style: { borderRadius: 20 },
            prefix: <LockOutlined className={'prefixIcon'} />
          }}
          rules={[
            { min: PASSWORD_MIN_LENGTH, message: 'Debe tener al menos 8 caracteres' },
            { max: PASSWORD_MAX_LENGTH, message: 'La contraseña es demasiado larga' },
            { required: true, message: ' ' },
            {
              pattern: PASSWORD_REGEX,
              message:
                'La contraseña debe tener al menos una letra mayúscula, una letra minúscula, un número y un caracter especial.'
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Las contraseñas no coinciden'));
              }
            })
          ]}
        />
      </ProForm>
    </AuthLayout>
  );
});

ForgetPassword.displayName = 'ForgetPassword';

export default ForgetPassword;
