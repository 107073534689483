import { FC, memo } from 'react';

// Components
import { Dropdown } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';

// Router
import { useNavigate } from 'react-router-dom';

// Redux
import { useDispatch } from 'react-redux';
import { authActions } from '../../../../store/slices/auth';

export type SiderTheme = 'light' | 'dark';

const GlobalHeaderRight: FC<{ dom: any }> = memo((props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <Dropdown
      menu={{
        items: [
          {
            key: 'user',
            // icon: <UserOutlined />,
            label: 'Mi información',
            onClick: () => {
              navigate('/perfil');
            }
          },
          { type: 'divider' },
          {
            icon: <LogoutOutlined />,
            key: 'logout',
            label: 'Cerrar Sesión',
            onClick: () => {
              dispatch(authActions.removeUser());
            }
          }
        ]
      }}
    >
      {props.dom}
    </Dropdown>
  );
});

GlobalHeaderRight.displayName = 'GlobalHeaderRight';

export default GlobalHeaderRight;
