/* eslint-disable react/no-unescaped-entities */
import { FC, memo } from 'react';

// Components
// import { Link } from 'react-router-dom';
import { Button, Col, Modal, Result, Row } from 'antd';

// // Images
// import confirmationIcon from '../../../../../../assets/images/confirmation.png';

// // Constants
// import { ROUTES } from '../../constants/routes-constants';

interface InscriptionConfirmationModalProps {
  open: boolean;
  title: string;
  subtitle: string;
  onClose: () => void;
}

const ButtonStyles = { width: '100%', borderRadius: 15 } as const;

export const ErrorModal: FC<InscriptionConfirmationModalProps> = memo((props) => {
  const { open, onClose } = props;

  return (
    <Modal closable={false} width={600} open={open} centered footer={false} onClose={onClose}>
      <Row justify="center" align="middle">
        <Col span={24}>
          <Result status="error" title={props.title} subTitle={props.subtitle} />
        </Col>

        <Col span={24}>
          <Button
            shape="round"
            type="primary"
            style={ButtonStyles}
            onClick={() => {
              onClose();
            }}
          >
            Cerrar
          </Button>
        </Col>
      </Row>
    </Modal>
  );
});

ErrorModal.displayName = 'ErrorModal';

export default ErrorModal;
