const backgroundImages = [
  'astilleros.jpg',
  'bahia.jpg',
  'balcarce.jpg',
  'cascada1.jpg',
  'cascada2.jpg',
  'copetonas.jpg',
  'delta.jpg',
  'ensenada.jpg',
  'estancia.jpg',
  'lujan.jpg',
  'museo.jpg',
  'olavarria.jpg',
  'puerto.jpg',
  'repu.jpg',
  'tandil.jpg',
  'tornquist.jpg',
  'tornquist2.jpg',
  'tornquist3.jpg',
  'zarate.jpg'
].sort(() => Math.random() - 0.5);

export const getBackgroundImagePath = (image: string) => {
  return `${process.env.PUBLIC_URL}/backgrounds/${image}`;
};

export const BACKGROUND_IMAGES_ARRAY = backgroundImages.map((image) =>
  getBackgroundImagePath(image)
);
