/* eslint-disable react-hooks/exhaustive-deps */
import { FC, memo } from 'react';
import { Col, Row } from 'antd';

// Components
import EventHeader from './components/header';
import SubscribeCard from './components/subscribeCard';
import { EventInscriptionInstructions } from './components/instructions';

// Interfaces
import type { Evento } from '../../../../interfaces/evento';

interface EventInscriptionContainerProps {
  evento: Evento;
}

export const EventInscriptionContainer: FC<EventInscriptionContainerProps> = memo((props) => {
  const { evento } = props;

  return (
    <>
      <div style={{ margin: 20, minHeight: 'calc(100vh - 190px)' }}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <EventHeader evento={evento!} action="Inscripción a" />
          </Col>
          <Col span={24} lg={16}>
            <EventInscriptionInstructions evento={evento!} />
          </Col>
          <Col span={24} lg={8}>
            <SubscribeCard evento={evento!} />
          </Col>
        </Row>
      </div>
    </>
  );
});

EventInscriptionContainer.displayName = 'EventInscriptionContainer';

export default EventInscriptionContainer;
