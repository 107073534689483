import { Link } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { AuthLayout } from '../components/layout';
import { Button, Modal, notification } from 'antd';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import ProForm, { ProFormInstance, ProFormText } from '@ant-design/pro-form';

// Services
import AuthService from '../../../services/auth.service';

// Constants
import { PRIMARY_COLOR } from '../../../constants/colors';

// Styles
import './styles.scss';

// Interfaces
import type { FC } from 'react';
import type { ForgotPasswordParams } from './interfaces';
import { PAGE_TITLE } from '../../../constants/page';

export const ForgetPassword: FC = memo(() => {
  const [disabled, setDisabled] = useState(false);
  const ref = useRef<ProFormInstance<ForgotPasswordParams>>();

  useEffect(() => {
    document.title = `${PAGE_TITLE} | Olvidé mi contraseña`;
  }, []);

  useEffect(() => {
    const listener = (event: any) => {
      if (!disabled)
        if (event.code === 'Enter' || event.code === 'NumpadEnter') {
          event.preventDefault();
          Modal.destroyAll();
          ref.current?.submit();
        }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [disabled]);

  const onSubmit = useCallback(async (data: ForgotPasswordParams) => {
    setDisabled(true);
    return await AuthService.forgotPassword(data.email)
      .then(() => {
        Modal.info({
          width: 600,
          centered: true,
          okText: 'Entendido',
          onCancel() {
            setDisabled(false);
          },
          title: 'Proceso iniciado',
          okButtonProps: { style: { backgroundColor: PRIMARY_COLOR } },
          content:
            'Si existe algun usuario con ese correo, se le enviara un correo con las instrucciones para restablecer su contraseña.'
        });
        return true;
      })
      .catch(() => {
        notification.error({
          closeIcon: false,
          placement: 'bottomLeft',
          description: 'Parece que el email ingresado no está registrado en el sistema.',
          message: 'Error'
        });
        return false;
      });
  }, []);

  return (
    <AuthLayout>
      <div className="login-page-subtitle">Olvidé mi contraseña</div>

      <ProForm<ForgotPasswordParams>
        formRef={ref}
        autoComplete="off"
        onFinish={onSubmit}
        submitter={{
          render(props) {
            return (
              <div className="login-page-footer">
                <div>
                  <div style={{ marginTop: 15 }}>
                    <Button
                      {...props.submitButtonProps}
                      shape="round"
                      size="large"
                      style={{ width: '100%' }}
                      type="primary"
                      onClick={props.submit}
                    >
                      Solicitar cambio de contraseña
                    </Button>
                  </div>

                  <div style={{ marginTop: 10 }}>
                    <Link to="/ingresar">
                      <Button shape="round" size="large" style={{ width: '100%' }} loading={false}>
                        Volver a inicio
                      </Button>
                    </Link>
                  </div>
                </div>

                <img
                  className="login-page-footer-logo"
                  src={`${process.env.PUBLIC_URL}/logo_gba.png`}
                />
              </div>
            );
          }
        }}
      >
        {/* Email field */}
        <ProFormText
          name="email"
          fieldProps={{
            size: 'large',
            style: { borderRadius: 20 },
            prefix: <UserOutlined className={'prefixIcon'} />
          }}
          placeholder={'Email'}
          rules={[
            {
              required: true,
              message: 'Debe completar este campo'
            },
            {
              type: 'email',
              message: 'Ingrese un email válido'
            }
          ]}
        />
      </ProForm>
    </AuthLayout>
  );
});

ForgetPassword.displayName = 'ForgetPassword';

export default ForgetPassword;
