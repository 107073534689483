/* eslint-disable react/react-in-jsx-scope */

import { FC, memo, useEffect, useRef } from 'react';

// Components
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import ProForm, { ProFormText } from '@ant-design/pro-form';

// Constants
import { ROUTES } from '../../../constants/routes-constants';
import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH, PASSWORD_REGEX } from '../../../utils/password';

// Services
import AuthService from '../../../services/auth.service';

// Styles
import './styles.scss';

// Utils
import useNotification from 'antd/es/notification/useNotification';
import { capitalizeFirstLetter } from '../../../utils/textTransform';

// Interfaces
import type { FormInstance } from 'antd/lib';
import type { UserCreateFormValues } from '../../../interfaces/user';

interface RegisterFormProps {
  onSubmit: (email: string) => void;
}

export const RegisterForm: FC<RegisterFormProps> = memo((props) => {
  const formRef = useRef<FormInstance>();
  const [notification, notificationHolder] = useNotification();

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        formRef.current?.submit();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  return (
    <>
      {notificationHolder}

      <div className="login-page-subtitle" style={{ marginBottom: 20 }}>
        Formulario de Registro
      </div>

      <ProForm<UserCreateFormValues>
        formRef={formRef}
        size="large"
        onFinish={async (values) => {
          values.nombre = capitalizeFirstLetter(values.nombre.trim());
          values.apellido = capitalizeFirstLetter(values.apellido.trim());
          return AuthService.register(values)
            .then(() => {
              props.onSubmit(values.email);
              return true;
            })
            .catch((err) => {
              if (err.response?.data?.message) {
                const message = err.response.data.message;

                if (message === 'El email ya esta en uso') {
                  formRef.current?.setFields([
                    {
                      name: 'email',
                      errors: ['El email ya está en uso']
                    }
                  ]);
                }
              } else {
                notification.error({
                  message: 'Error al registrarse',
                  description:
                    'Ocurrió un error al intentar registrarse. Por favor, intente nuevamente.'
                });
              }

              return false;
            });
        }}
        submitter={{
          render(props) {
            return (
              <div style={{ textAlign: 'center' }}>
                <Button
                  type="primary"
                  {...props.submitButtonProps}
                  size="large"
                  shape="round"
                  style={{ width: '100%', marginTop: 10 }}
                  onClick={props.submit || props.onSubmit}
                >
                  Confirmar
                </Button>

                <Link to={ROUTES.HOMEPAGE_ROUTE}>
                  <Button size="large" shape="round" style={{ width: '100%', marginTop: 15 }}>
                    Volver al inicio
                  </Button>
                </Link>

                <img
                  style={{ marginTop: 50 }}
                  className="login-page-footer-logo"
                  src={`${process.env.PUBLIC_URL}/logo_gba.png`}
                />
              </div>
            );
          }
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <ProFormText
              name="email"
              validateFirst
              label="Email"
              placeholder="Ingrese su email"
              fieldProps={{ style: { borderRadius: 20 } }}
              rules={[
                { required: true, message: ' ' },
                { type: 'email', message: 'Ingrese un email válido' }
              ]}
            />
          </Col>

          <Col span={24} lg={12}>
            <ProFormText
              name="nombre"
              validateFirst
              label="Nombre"
              placeholder="Ingrese su nombre"
              rules={[{ required: true, message: ' ' }]}
              fieldProps={{ style: { borderRadius: 20 } }}
            />
          </Col>

          <Col span={24} lg={12}>
            <ProFormText
              name="apellido"
              validateFirst
              label="Apellido"
              placeholder="Ingrese su apellido"
              rules={[{ required: true, message: ' ' }]}
              fieldProps={{ style: { borderRadius: 20 } }}
            />
          </Col>

          <Col span={24} lg={12}>
            <ProFormText.Password
              validateFirst
              name="password"
              label="Contraseña"
              tooltip="La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula y un número"
              fieldProps={{
                style: { borderRadius: 20 },
                prefix: <LockOutlined className={'prefixIcon'} />
              }}
              placeholder={'Contraseña'}
              rules={[
                { required: true, message: ' ' },
                { min: PASSWORD_MIN_LENGTH, message: 'Debe tener al menos 8 caracteres' },
                { max: PASSWORD_MAX_LENGTH, message: 'La contraseña es demasiado larga' },
                {
                  pattern: PASSWORD_REGEX,
                  message:
                    'La contraseña debe tener al menos una letra mayúscula, una letra minúscula, un número y un caracter especial.'
                }
              ]}
            />
          </Col>

          <Col span={24} lg={12}>
            <ProFormText.Password
              validateFirst
              label="Repetir contraseña"
              name="password_repeat"
              fieldProps={{
                style: { borderRadius: 20 },
                prefix: <LockOutlined className={'prefixIcon'} />
              }}
              placeholder={'Contraseña'}
              rules={[
                { min: PASSWORD_MIN_LENGTH, message: 'Debe tener al menos 8 caracteres' },
                { max: PASSWORD_MAX_LENGTH, message: 'La contraseña es demasiado larga' },
                { required: true, message: ' ' },
                {
                  pattern: PASSWORD_REGEX,
                  message:
                    'La contraseña debe tener al menos una letra mayúscula, una letra minúscula, un número y un caracter especial.'
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Las contraseñas no coinciden'));
                  }
                })
              ]}
            />
          </Col>
        </Row>
      </ProForm>
    </>
  );
});

RegisterForm.displayName = 'RegisterForm';

export default RegisterForm;
