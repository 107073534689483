import { FC, memo, useMemo } from 'react';

// Components
import CountDown from '../../../../../../components/event/countDown';
import { CountDownTimeTag } from '../../../../../../components/event/countDown/timeTag';

// Utils
import dayjs from 'dayjs';

// Interfaces
import type { PublicEventRead } from '../../../../../../interfaces/evento';

interface SubscribeCardProps {
  isAcredited?: boolean;
  isInscripted?: boolean;
  evento: PublicEventRead;
}

const ItemStyle = { marginTop: 20, textAlign: 'center' } as const;

const EventoCountDown: FC<{
  action: string;
  endDate?: string | null;
  startDate?: string | null;
}> = memo((props) => {
  const { startDate, endDate, action } = props;

  const date = useMemo(() => {
    if (!startDate || !endDate) return null;
    const isClosed = dayjs().isAfter(dayjs(endDate));
    if (isClosed) return null;
    return dayjs().isAfter(dayjs(startDate)) ? new Date(endDate!) : new Date(startDate!);
  }, [startDate, endDate]);

  if (!date) return null;

  return (
    <>
      <div style={ItemStyle}>
        <CountDownTimeTag action={action} endDate={endDate!} startDate={startDate!} />
      </div>

      <div style={ItemStyle}>
        <CountDown date={date} />
      </div>
    </>
  );
});

EventoCountDown.displayName = 'EventoCountDown';

export const EventoInscriptionCountDown: FC<SubscribeCardProps> = memo(
  (props: SubscribeCardProps) => {
    return (
      <EventoCountDown
        action="la inscripción al evento"
        endDate={props.evento.inscripcion_fin}
        startDate={props.evento.inscripcion_inicio}
      />
    );
  }
);

export const SelectionCountDown: FC<SubscribeCardProps> = memo((props: SubscribeCardProps) => {
  return (
    <EventoCountDown
      action="la selección de entidades"
      endDate={props.evento.seleccion_fin}
      startDate={props.evento.seleccion_inicio}
    />
  );
});

SelectionCountDown.displayName = 'SelectionCountDown';
EventoInscriptionCountDown.displayName = 'EventoInscriptionCountDown';

export const EventCountDown: FC<SubscribeCardProps> = memo((props) => {
  const { evento, isInscripted, isAcredited } = props;

  if (isAcredited) return null;

  if (isInscripted) {
    if (evento.seleccion_inicio && evento.seleccion_fin) {
      return <SelectionCountDown evento={evento} />;
    } else {
      return null;
    }
  }

  return <EventoInscriptionCountDown evento={evento} />;
});

EventCountDown.displayName = 'EventCountDown';

export default EventCountDown;
