/* eslint-disable no-empty */
import Axios from 'axios';

import { API_URL } from '../config';
import { authActions } from '../store/slices/auth';
import { store } from '../store/store';
import { history } from '../RootComponent';

const instance = Axios.create({
  baseURL: API_URL
});

/**
 * instance request config.
 */
instance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) config.headers['authorization'] = `Bearer ${accessToken}`;
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    const originalRequest = err.config;

    if ([404].includes(err.response.status)) {
      history.push('/404');
    }

    console.log(err.request.responseURL, err.response.status);

    if (err.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            return instance(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(function (resolve, reject) {
        console.log('request sss');
        Axios.post(
          `${API_URL}/auth/refresh`,
          {},
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('refreshToken')
            }
          }
        )
          .then(({ data }) => {
            localStorage.setItem('accessToken', data.token);
            localStorage.setItem('refreshToken', data.refresh_token);
            instance.defaults.headers.common['Authorization'] = 'Bearer ' + data.token;
            originalRequest.headers['Authorization'] = 'Bearer ' + data.token;
            processQueue(null, data.token);
            resolve(instance(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            store.dispatch(authActions.removeUser());
            reject(err);
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(err);
  }
);

export default instance;
