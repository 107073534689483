import { Card, Col, Row } from 'antd';

// Utils
import ReactHtmlParser from 'react-html-parser';

// Interfaces
import { memo, type FC } from 'react';
import type { Evento } from '../../../../../interfaces/evento';

interface EventInscriptionInstructionsProps {
  evento: Evento;
}

export const EventInscriptionInstructions: FC<EventInscriptionInstructionsProps> = memo((props) => {
  const { evento } = props;

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card styles={{ header: { border: 0 } }} title={'Detalles de inscripción'}>
          <div className="quill-text">
            {ReactHtmlParser(evento.inscripcion_instrucciones || '')}
          </div>
        </Card>
      </Col>
    </Row>
  );
});

EventInscriptionInstructions.displayName = 'EventInscriptionInstructions';

export default EventInscriptionInstructions;
