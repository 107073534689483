import axios from '../config/axios';

import type { SectorActividad } from '../interfaces/sectorActividad';

const PATH = `sector-actividades`;

/**
 * @description Recupera desde la API los sectores de actividad
 */
export const getSectoresActividad = async () => {
  return await axios.get<SectorActividad[]>(PATH).then((response) => {
    return response.data;
  });
};
