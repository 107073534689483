import { memo } from 'react';

import { Col, Row } from 'antd';

import PrevEvents from './components/prevEvents';
import NextEvents from './components/nextEvents';
import { HomeBanner1 } from './components/banners/banner1';
import { HomeBanner2 } from './components/banners/banner2';

export const HomePageContainer = memo(() => {
  return (
    <>
      <Row>
        <Col span={24}>
          <HomeBanner1 />
        </Col>

        <Col span={24}>
          <NextEvents />
        </Col>

        <Col span={24}>
          <PrevEvents />
        </Col>

        <Col span={24}>
          <HomeBanner2 />
        </Col>
      </Row>
    </>
  );
});

HomePageContainer.displayName = 'HomePageContainer';
