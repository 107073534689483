import axios from '../config/axios';
import type { Producto } from '../interfaces/producto';

const PATH = `productos`;

/**
 * @description Recupera desde la API los productos
 */
export const getProductos = async () => {
  return await axios.get<Producto[]>(PATH).then((response) => {
    return response.data;
  });
};
