/* eslint-disable react/no-unescaped-entities */
import { Button, Col, Modal, Row, Spin } from 'antd';

import { FC, memo, useCallback, useMemo } from 'react';

// Redux
import { useAppDispatch, useAppSelector } from '../../../store/store';

// Interfaces
import { PublicEventRead } from '../../../interfaces/evento';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { acreditationModalActions } from '../../../store/slices/acreditationModal';

interface AcreditationConfirmationModalProps {
  evento: PublicEventRead;
  initialStatus?: 'loading' | 'success' | 'error';
}

const ButtonStyles = { width: '100%', borderRadius: 15 } as const;

export const AcreditationConfirmationModal: FC<AcreditationConfirmationModalProps> = memo(
  (props) => {
    const { evento } = props;

    const dispatch = useAppDispatch();
    const open = useAppSelector((state) => state.acreditationModal.open);
    const status = useAppSelector((state) => state.acreditationModal.status);
    const errorMessage = useAppSelector((state) => state.acreditationModal.errorMessage);

    const onClose = useCallback(() => {
      dispatch(acreditationModalActions.setAcreditationModal({ open: false }));
    }, [dispatch]);

    const content = useMemo(() => {
      switch (status) {
        case 'success':
          return {
            icon: <CheckCircleFilled style={{ fontSize: 80, color: '#52c41a' }} />,
            title: 'Acreditación exitosa',
            description: `Te has acreditado correctamente al evento ${evento.nombre}.`
          };

        case 'error':
          return {
            icon: <CloseCircleFilled style={{ fontSize: 70, color: '#f5222d' }} />,
            title: 'Ocurrió un error al acreditarte en el evento',
            description:
              errorMessage ||
              'Ocurrió un error inesperado al intentar acreditarte en el evento. Por favor intenta nuevamente o acercate al stand.'
          };

        default:
          return null;
      }
    }, [errorMessage, evento.nombre, status]);

    return (
      <>
        <Modal
          centered
          open={open}
          width={550}
          footer={false}
          destroyOnClose
          closable={false}
          onClose={onClose}
          onCancel={onClose}
          maskClosable={false}
        >
          {status === 'loading' ? (
            <Spin tip={<h3 style={{ color: 'gray' }}>Procesando acreditación...</h3>}>
              <div style={{ textAlign: 'center', padding: 60 }}></div>
            </Spin>
          ) : (
            <Row justify="center" align="middle">
              <Col span={24}>
                <div style={{ textAlign: 'center', marginTop: 20 }}>{content?.icon}</div>
              </Col>

              <Col span={24}>
                <div style={{ textAlign: 'center', marginBottom: 25 }}>
                  <h2>{content?.title}</h2>
                  <p style={{ fontSize: 16 }}>{content?.description}</p>
                </div>
              </Col>

              <Col span={24}>
                <Button shape="round" style={ButtonStyles} type="primary" onClick={onClose}>
                  Cerrar
                </Button>
              </Col>
            </Row>
          )}
        </Modal>
      </>
    );
  }
);

AcreditationConfirmationModal.displayName = 'AcreditationConfirmationModal';

export default AcreditationConfirmationModal;
