/* eslint-disable react/react-in-jsx-scope */

import { FC, memo, useEffect, useState } from 'react';

// Styles
import './styles.scss';
import { AuthLayout } from '../components/layout';
import RegisterForm from './form';
import RegisterSuccessComponent from './sucess';

// Constants
import { PAGE_TITLE } from '../../../constants/page';

export const RegisterPage: FC = memo(() => {
  const [registeredEmail, setRegisteredEmail] = useState<string>('');

  useEffect(() => {
    document.title = `${PAGE_TITLE} | Registro`;
  }, []);

  return (
    <AuthLayout large>
      {registeredEmail ? (
        <RegisterSuccessComponent email={registeredEmail} />
      ) : (
        <RegisterForm onSubmit={(email) => setRegisteredEmail(email)} />
      )}
    </AuthLayout>
  );
});

RegisterPage.displayName = 'RegisterPage';

export default RegisterPage;
