// import jwtDecode from 'jwt-decode';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: { open: boolean } = { open: false };

const pendingRegistrationModalSlice = createSlice({
  name: 'pendingRegistrationModal',
  initialState,
  reducers: {
    setPendingRegistrationModal(state, action: PayloadAction<boolean>) {
      state.open = action.payload;
    }
  }
});

export const pendingRegistrationModalActions = pendingRegistrationModalSlice.actions;

export default pendingRegistrationModalSlice.reducer;
