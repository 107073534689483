import { memo, useEffect } from 'react';
import StatusComponent from './StatusComponent';
import { PAGE_TITLE } from '../../constants/page';

const Pagina404: React.FC = memo(() => {
  useEffect(() => {
    document.title = `${PAGE_TITLE} | 404 - Página no encontrada`;
  }, []);

  return (
    <StatusComponent
      status="404"
      title="Oops 404!"
      // headerTitle="Error 404"
      subTitle="Parece que no encontramos lo que estabas buscando."
    />
  );
});

Pagina404.displayName = 'Pagina404';

export default Pagina404;
