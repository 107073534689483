import { FC } from 'react';
import CD from 'count-down-react';

import type { CounterProps } from 'count-down-react/dist/index.types';

const containerStyle = {
  display: 'flex',
  justifyContent: 'space-around',
  maxWidth: '80%',
  margin: '0 auto'
} as const;

const Item: FC<{ time: number; label: string }> = ({ time, label }) => {
  return (
    <div>
      <b style={{ fontSize: 20 }}>{time}</b>
      <div>{label}</div>
    </div>
  );
};

const CoundownRenderer: CounterProps['renderer'] = ({ days, hours, minutes, seconds }: any) => {
  return (
    <div>
      <div style={containerStyle}>
        <Item time={days} label="Días" key="days" />
        <Item time={hours} label="Horas" key="hours" />
        <Item time={minutes} label="Minutos" key="minutes" />
        <Item time={seconds} label="Segundos" key="seconds" />
      </div>
    </div>
  );
};

export const CountDown: FC<Omit<CounterProps, 'renderer'>> = (props) => {
  return <CD {...props} renderer={CoundownRenderer} />;
};

CountDown.displayName = 'CountDown';

export default CountDown;
