import { memo, useEffect } from 'react';
import StatusComponent from './StatusComponent';
import { PAGE_TITLE } from '../../constants/page';

const Pagina500: React.FC = memo(() => {
  useEffect(() => {
    document.title = `${PAGE_TITLE} | 500 - Error`;
  }, []);

  return (
    <StatusComponent
      status="500"
      title="Oops!"
      subTitle="Por un error inesperado, no podemos mostrarte esta página."
    />
  );
});

Pagina500.displayName = 'Pagina500';

export default Pagina500;
