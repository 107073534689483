import axios from '../config/axios';

import type {
  Inscripcion,
  UserInscripcion,
  SelectionsFormData,
  InscripcionQueryParams,
  NewInscripcionFormValues,
  MyInscriptionsQueryParams,
  UserInscriptionFormValues,
  InscripcionDetalle,
  EditInscripcionFormValues
} from '../interfaces/inscripcion';
import type { PaginatedResponse } from '../interfaces/baseApiEntity';

const PATH = `inscripciones`;

/**
 * @description Recupera un listado de inscripciones para un evento
 * @requires Permiso INSCRIPCIONES_LIST
 */
export const getInscripciones = async (params: InscripcionQueryParams) => {
  return await axios.get<PaginatedResponse<Inscripcion>>(`${PATH}`, { params }).then((response) => {
    return response.data;
  });
};

/**
 * @description Recupera una inscripción por su id
 * @requires Permiso INSCRIPCIONES_READ
 */
export const getInscripcion = async (id: number) => {
  return await axios.get<InscripcionDetalle>(`${PATH}/${id}`).then((response) => {
    return response.data;
  });
};

/**
 * @description Recupera una inscripción por su id
 */
export const getMyInscripcionFor = async (event_id: number) => {
  return await axios.get<InscripcionDetalle>(`eventos/${event_id}/inscripcion`).then((response) => {
    return response.data;
  });
};

/**
 * @description Crea una nueva inscripción para un evento en la base de datos
 * @requires Permiso INSCRIPCIONES_CREATE
 */
export const newInscripcion = async (evento_id: number, data: NewInscripcionFormValues) => {
  return await axios.post<Inscripcion>(`${PATH}`, { evento_id, ...data }).then((response) => {
    return response.data;
  });
};

/**
 * @description Actualiza una inscripción en la base de datos
 * @requires Permiso INSCRIPCIONES_UPDATE
 */
export const updateInscripcion = async (id: number, data: EditInscripcionFormValues) => {
  return await axios.patch<Inscripcion>(`${PATH}/${id}`, data).then((response) => {
    return response.data;
  });
};

/**
 * @description Elimina una inscripción de la base de datos
 * @requires Permiso INSCRIPCIONES_DELETE
 */
export const deleteInscripcion = async (id: number) => {
  return await axios.delete(`${PATH}/${id}`);
};

/**
 * @description endpoint para registrarme a un evento en particular
 * @requires Permiso INSCRIPCIONES_SELF_CREATE
 */
export const registerToEvent = async (id: number, data: UserInscriptionFormValues) => {
  return await axios.post<Inscripcion>(`${PATH}/${id}/selfCreate`, data);
};

/**
 * @description Recupera un listado de inscripciones para el usuario actual
 * @requires Permiso INSCRIPCIONES_SELF_LIST
 */
export const myInscriptions = async (params: MyInscriptionsQueryParams) => {
  return await axios
    .get<PaginatedResponse<UserInscripcion>>(`${PATH}/selfList`, { params })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Indica si el usuario actual está inscripto o no al evento
 */
export const selfEventStatus = async (evento_id: number) => {
  return await axios
    .get<{ inscriptedAmount: number; acreditedAmount: number }>(
      `eventos/${evento_id}/selfEventStatus`
    )
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Indica si el usuario actual está inscripto o no al evento
 */
export const isInscripted = async (evento_id: number) => {
  return await axios
    .get<{ isInscripted: boolean }>(`eventos/${evento_id}/selfIsInscripted`)
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Registra las selecciones que hice con mi empresa para un evento
 * @requires Permiso INSCRIPCIONES_REUNIONES_DESEADAS
 */
export const registerMySelections = async (inscripcion_id: number, data: SelectionsFormData) => {
  return await axios.post(`${PATH}/${inscripcion_id}/self_reuniones_deseadas`, data);
};

/**
 * @description Registra la selecciones de una empresa para un evento
 * @param evento_id: number
 * @requires Permiso INSCRIPCIONES_SELF_REUNIONES_DESEADAS
 */
export const registerSelections = async (inscripcion_id: number, data: SelectionsFormData) => {
  return await axios.post(`${PATH}/${inscripcion_id}/reuniones_deseadas`, data);
};

// Acreditaciones

export const InscripcionService = {
  myInscriptions,
  selfEventStatus,
  isInscripted,
  getInscripcion,
  newInscripcion,
  getInscripciones,
  updateInscripcion,
  deleteInscripcion,
  registerToEvent,
  getMyInscripcionFor,
  registerSelections,
  registerMySelections
};
