import React, { ReactNode } from 'react';

import Footer from '../components/Footer';
import ProLayout from '@ant-design/pro-layout';
import { UserOutlined } from '@ant-design/icons';
import RightContent from '../components/RightContent';
import { NotificationBell } from '../components/Notifications';

// Navigation
import { Link, useLocation } from 'react-router-dom';

// Utils
import getMenuItems from './getMenuItems';
import { useAppSelector } from '../../../store/store';
import { useWindowSize } from '../../../utils/useWindowSize';

// Constants
import logo from '../../../assets/images/logo_short.png';
import { PRIMARY_COLOR } from '../../../constants/colors';

// Interfaces
import type { IUser } from '../../../interfaces/user';

interface LayoutProps {
  children: ReactNode;
  isWhiteLayout?: boolean;
}

const contentStyle = { margin: 0, padding: 0 } as const;

export const Layout: React.FC<LayoutProps> = (props) => {
  const { width } = useWindowSize();

  const location = useLocation();
  const isMobile = width && width <= 767;

  const user = useAppSelector((state) => state.auth.user) as IUser;

  const routes = getMenuItems(isMobile as boolean, user);

  return (
    <ProLayout
      layout="mix"
      logo={
        <div style={{ display: 'flex', alignItems: 'center', color: 'white' }}>
          <Link to="/">
            <img style={{ height: 18 }} src={logo}></img>
          </Link>
        </div>
      }
      fixSiderbar
      title={false}
      route={routes}
      location={location}
      contentStyle={contentStyle}
      style={{ height: '100vh' }}
      footerRender={() => <Footer />}
      collapsed={!isMobile ? true : undefined}
      className={props.isWhiteLayout ? 'white-layout' : ''}
      collapsedButtonRender={!isMobile ? () => null : undefined}
      actionsRender={() => [<NotificationBell key="notification" />]}
      avatarProps={{
        size: 'small',
        icon: <UserOutlined />,
        style: { backgroundColor: PRIMARY_COLOR },
        title: `${user.nombre} ${user.apellido}`,
        render: (props, dom) => {
          return <RightContent dom={dom} />;
        }
      }}
      menuItemRender={(item, dom) => {
        return <Link to={item.path as string}>{dom}</Link>;
      }}
      token={{
        sider: {
          colorMenuBackground: 'white'
          // colorTextMenu: PRIMARY_COLOR,
          // colorTextMenuActive: isMobile ? undefined : PRIMARY_COLOR,
          // colorBgMenuItemHover: isMobile ? undefined : PRIMARY_COLOR,
          // colorTextMenuSelected: isMobile ? undefined : PRIMARY_COLOR,
          // colorTextMenuItemHover: isMobile ? undefined : PRIMARY_COLOR,
          // colorTextMenuSecondary: isMobile ? undefined : PRIMARY_COLOR,
          // colorTextSubMenuSelected: isMobile ? undefined : PRIMARY_COLOR,
          // colorBgMenuItemCollapsedElevated: isMobile ? undefined : PRIMARY_COLOR
        }
      }}
    >
      {props.children}
    </ProLayout>
  );
};

export default Layout;
