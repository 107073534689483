import { useMemo, type FC } from 'react';

// Components
import AdminLayout from './admin';
import LoggedLayout from './public';
import NotificationDrawer from '../notifications/notificationDrawer';

// Redux
import { useAppSelector } from '../../store/store';

// Constants
import { PERMISSIONS } from '../../constants/permissions';

const ADMIN_PERMISSIONS = [
  PERMISSIONS.EVENTOS_LIST,
  PERMISSIONS.ENTIDADES_LIST,
  PERMISSIONS.EVENTOS_CREATE
] as const;

export const Layout: FC<{ children: any; layout?: string }> = (props) => {
  const user = useAppSelector((state) => state.auth.user);

  const isAdmin = useMemo(() => {
    if (!user) return false;
    const permissions =
      user?.roles.map((r) => r.permissions?.map((p) => p.descripcion)).flat() || [];
    return ADMIN_PERMISSIONS.some((p) => permissions.includes(p));
  }, [user]);

  const CurrentLayout = isAdmin ? AdminLayout : LoggedLayout;

  return (
    <>
      <NotificationDrawer />
      <CurrentLayout {...props} isWhiteLayout={props.layout === 'white'} />
    </>
  );
};
