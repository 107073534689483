import { Col, Row } from 'antd';

import logoGBA from '../../../../assets/images/logo_gba.png';

const baseStyle = {
  alignItems: 'baseline',
  marginBottom: 20
};

export default function Footer() {
  const style = baseStyle;
  return (
    <div style={{ backgroundColor: 'white', padding: 20, paddingBottom: 0 }}>
      <Row gutter={[16, 16]} justify="space-between" style={style} align="middle">
        <Col>
          <img src={logoGBA} height={50} />
        </Col>
        <Col></Col>
      </Row>
    </div>
  );
}
