/* eslint-disable no-useless-catch */
import axios from '../config/axios';
import { API_URL } from '../config';

import type { IUser } from '../interfaces/user';
import { store } from '../store/store';
import { authActions } from '../store/slices/auth';
import { EntidadActions } from '../store/slices/entidad';
import { pendingRegistrationModalActions } from '../store/slices/pendingRegistrationModal';
import { getFromLocalStorageWithExpiry, setLocalStorageWithExpiry } from '../utils/localstorage';

/**
 * @description Recupera desde la API el usuario actual
 * @throws {Error} Si el usuario no está autenticado
 * @returns {Promise<IUser>} Usuario actual
 */
const getCurrentUser = async () => {
  return await axios.get<IUser>(`${API_URL}/users/me`).then((response) => {
    const user = response.data;
    store.dispatch(authActions.setUser({ user }));

    if (user.entidad) {
      store.dispatch(EntidadActions.fetchEntidad(user.entidad?.id));
    } else {
      if (user.roles.some((r) => r.descripcion === 'EMPRESA')) {
        const pendingRegistration = getFromLocalStorageWithExpiry(`pendingRegistration-${user.id}`);
        if (!pendingRegistration) {
          setLocalStorageWithExpiry(`pendingRegistration-${user.id}`, true, 1000 * 60 * 60 * 24);
          store.dispatch(pendingRegistrationModalActions.setPendingRegistrationModal(true));
        }
      }
    }
    return user;
  });
};

/**
 * @description Cambia la contraseña del usuario actual
 * @param {Object} data Objeto con las contraseñas
 * @param {string} data.old_password Contraseña actual
 * @param {string} data.new_password Nueva contraseña
 * @throws {Error} Si la contraseña actual no es correcta
 * @returns {Promise<void>}
 */
const changePassword = async (data: { old_password: string; new_password: string }) => {
  return await axios.patch(`${API_URL}/me/password`, data).then((response) => {
    return response.data;
  });
};

const MeService = {
  getCurrentUser,
  changePassword
};

export default MeService;
