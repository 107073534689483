import { Col, Flex, Row } from 'antd';
import { Link } from 'react-router-dom';

// Constants
import { ROUTES } from '../../../constants/routes-constants';

// Interfaces
import type { FC } from 'react';
import { BACKGROUND_IMAGES_ARRAY } from '../../../constants/images';

// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper/modules';

// Redux
import { homeActions } from '../../../store/slices/home';
import { useAppDispatch, useAppSelector } from '../../../store/store';

import 'swiper/css';
import 'swiper/css/effect-fade';

interface AuthLayoutProps {
  large?: boolean;
  children: React.ReactNode;
}

export const AuthLayout: FC<AuthLayoutProps> = (props) => {
  const dispatch = useAppDispatch();
  const currentBackground = useAppSelector((state) => state.home.currentBackground);

  return (
    <div>
      <Row>
        <Col span={0} md={props.large ? 8 : 12} lg={props.large ? 12 : 16}>
          <div className="login-page-background-overlay"></div>
          <Swiper
            speed={4000}
            effect={'fade'}
            direction="horizontal"
            autoplay={{ delay: 5000 }}
            modules={[EffectFade, Autoplay]}
            initialSlide={currentBackground}
            onSlideChange={(swiper: any) => {
              dispatch(homeActions.setCurrentBackground({ currentBackground: swiper.activeIndex }));
            }}
          >
            {BACKGROUND_IMAGES_ARRAY.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  src={image}
                  alt="background"
                  style={{ width: '100%', height: '100vh', objectFit: 'cover' }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Col>

        <Col span={24} md={props.large ? 16 : 12} lg={props.large ? 12 : 8}>
          <Flex align="center" vertical justify="space-evenly" style={{ height: '100vh' }}>
            <div>
              <Link to={ROUTES.HOMEPAGE_ROUTE}>
                <div className="login-page-subtitle">
                  <img
                    className="login-page-footer-logo"
                    src={`${process.env.PUBLIC_URL}/logo_full.png`}
                  />
                </div>
              </Link>
            </div>

            <div style={{ minWidth: 325, maxWidth: props.large ? '80%' : 325, overflow: 'scroll' }}>
              {props.children}
            </div>
          </Flex>
        </Col>
      </Row>
    </div>
  );
};
