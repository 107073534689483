import { memo } from 'react';
import { BackgroundBanner } from '../../../../../components/banner/backgroundBanner';

export const HomeBanner1 = memo(() => {
  return (
    <BackgroundBanner
      title="Sistema de eventos empresariales"
      description={[
        'Una herramienta para incentivar los intercambios comerciales entre PyMEs, grandes empresas y cooperativas.'
      ]}
    />
  );
});

HomeBanner1.displayName = 'HomeBanner1';
