import { Avatar, Button, Drawer, Empty, List } from 'antd';
import { memo, useCallback } from 'react';

// Redux
import { useAppDispatch, useAppSelector } from '../../store/store';
import { notificationsActions } from '../../store/slices/notifications';
import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';

// Constants
import { PRIMARY_COLOR } from '../../constants/colors';

import './notificationDrawer.scss';

export const NotificationDrawer = memo(() => {
  const dispatch = useAppDispatch();
  const open = useAppSelector((state) => state.notifications.open);

  const onClose = useCallback(() => {
    dispatch(notificationsActions.closeNotifications());
  }, [dispatch]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title="Notificaciones"
      className="notification-drawer"
      footer={[
        <Button shape="round" style={{ width: '100%' }} type="primary" key="clean_notifications">
          Limpiar notificaciones
        </Button>
      ]}
    >
      <List
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No hay notificaciones disponibles"
            />
          )
        }}
        dataSource={[
          {
            icon: <Avatar icon={<CalendarOutlined />} style={{ backgroundColor: PRIMARY_COLOR }} />,
            title: 'Notificación 1',
            description: 'Descripción de la notificación 1'
          },
          {
            icon: <Avatar icon={<ClockCircleOutlined />} />,
            title: 'Notificación 2',
            description: 'Descripción de la notificación 2'
          }
        ]}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta avatar={item.icon} title={item.title} description={item.description} />
          </List.Item>
        )}
      />
    </Drawer>
  );
});

NotificationDrawer.displayName = 'NotificationDrawer';

export default NotificationDrawer;
